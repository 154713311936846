var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.cssClasses.fixedSpacer},[_c('div',{class:_vm.cssClasses.fixedWrapper},[_c('div',{class:_vm.cssClasses.wrapperClass},_vm._l((_vm.tabList),function(item,i){return _c('a',{key:("tab_" + i),class:[
          _vm.cssClasses.buttonClass,
          item.contentTypeId === 225 && _vm.cssClasses.specBtnClass,
          item.contentTypeId === 235 && _vm.cssClasses.ulkerBtnClass ],on:{"click":function($event){return _vm.selectTab(item)}}},[_c('span',{class:[
            _vm.cssClasses.buttonTextClass,
            i === _vm.selectedIndex ? _vm.cssClasses.selectedTabClass : null,
            item.contentTypeId === 225 && _vm.cssClasses.specClass,
            item.contentTypeId === 235 && _vm.cssClasses.ulkerClass ]},[_vm._v(_vm._s(item[_vm.nameField]))])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }