<template>
  <div :class="cssClasses.fixedSpacer">
    <div :class="cssClasses.fixedWrapper">
      <div :class="cssClasses.wrapperClass">
        <a
          v-for="(item, i) in tabList"
          :key="`tab_${i}`"
          :class="[
            cssClasses.buttonClass,
            item.contentTypeId === 225 && cssClasses.specBtnClass,
            item.contentTypeId === 235 && cssClasses.ulkerBtnClass,
          ]"
          @click="selectTab(item)"
        >
          <span
            :class="[
              cssClasses.buttonTextClass,
              i === selectedIndex ? cssClasses.selectedTabClass : null,
              item.contentTypeId === 225 && cssClasses.specClass,
              item.contentTypeId === 235 && cssClasses.ulkerClass,
            ]"
            >{{ item[nameField] }}</span
          >
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BrandTab',
  props: {
    tabList: {
      type: Array,
    },
    idField: {
      type: String,
      required: true,
    },
    nameField: {
      type: String,
      required: true,
    },
    selectedTabId: {
      type: [Number, String],
    },
    noScroll: {
      type: Boolean,
    },
    fixed: {
      type: Boolean,
    },
  },
  computed: {
    selectedIndex() {
      return this.tabList.findIndex(x => x[this.idField] === this.selectedTabId);
    },
    cssClasses() {
      let fixedWrapper = [];
      let fixedSpacer = [];
      let wrapperClass = [this.$style[`tabs-wrapper`]];
      let buttonClass = [this.$style[`tabs-wrapper-button`]];
      let buttonTextClass = [this.$style[`tabs-wrapper-button-span`]];
      let specClass = [this.$style[`specClass`]];
      let specBtnClass = [this.$style[`specBtnClass`]];
      let ulkerClass = [this.$style[`ulkerClass`]];
      let ulkerBtnClass = [this.$style[`ulkerBtnClass`]];
      let selectedTabClass = [this.$style[`tabs-wrapper-button-span-selected`]];

      if (this.fixed) {
        fixedWrapper.push(this.$style[`tabs-wrapper-fixed`]);
        fixedSpacer.push(this.$style[`tabs-wrapper-fixed-spacer`]);
      }
      if (this.noScroll) {
        wrapperClass.push(this.$style[`tabs-wrapper-no-scroll`]);
        buttonTextClass.push(this.$style[`tabs-wrapper-button-span-no-scroll`]);
      }
      return {
        wrapperClass,
        buttonClass,
        buttonTextClass,
        selectedTabClass,
        fixedWrapper,
        fixedSpacer,
        specClass,
        specBtnClass,
        ulkerClass,
        ulkerBtnClass,
      };
    },
  },
  methods: {
    selectTab(item) {
      this.$emit('select', item);
    },
  },
};
</script>
<style lang="scss" module>
.tabs-wrapper {
  overflow: auto;
  white-space: nowrap;
  padding: palette-padding-level(15, 10);
  background-color: #f8f9f9;
  height: 70px;
  border-top: 0.5px solid #b4c2d399;
  overflow-y: hidden;
  &-no-scroll {
    display: flex;
    width: 100%;
    text-align: center;
    overflow: hidden !important;
  }
  &-fixed {
    width: 100%;
    position: fixed;
    z-index: 5;
    &-spacer {
      padding-bottom: 70px;
    }
  }
  &-button {
    margin-left: 6px;
    margin-right: 6px;
    background: linear-gradient(to left, #d20051 0%, #ffa300 100%);
    box-sizing: border-box;
    border-radius: 22px;
    display: inline-block;
    padding: 2px;
    width: inherit;
    text-decoration: none;
    &.specBtnClass {
      background: linear-gradient(to left, #256eae 0%, #54a8f1 100%);
    }
    &.ulkerBtnClass {
      background: #ec1c29;
    }
    &-span {
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 500;
      background: white;
      padding: palette-padding-level(10, 20);
      border-radius: 22px;
      display: block;
      justify-content: center;
      height: 100%;
      transition: all 0.25s ease;
      color: palette-color-level('grey', '50');
      width: 100%;
      &.specClass {
        background: linear-gradient(to left, #256eae 0%, #54a8f1 100%) !important;
        color: #fff;
      }
      &.ulkerClass {
        background: #ec1c29 !important;
        color: #fff;
      }
      &-no-scroll {
        font-size: 14px !important;
      }
      &-selected {
        background: linear-gradient(to left, #d20051 0%, #ffa300 100%) !important;
        color: palette-color-level('white', '100');
      }
      &-selected.specClass {
        background: linear-gradient(to left, #256eae 0%, #54a8f1 100%) !important;
        color: #fff;
      }
    }
  }
}
</style>
