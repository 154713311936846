import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      dataLayerPushCounter: 0,
      adSlots: {
        large: [
          {
            image: '/21852615636/pmaktif.com/mobil_anasayfa_320x100',
            sizes: [
              [300, 50],
              [320, 100],
              [300, 100],
              [300, 31],
              [320, 50],
              [300, 75],
            ],
            id: 'div-gpt-ad-1587047396774-0',
          },
        ],
        small: [
          {
            image: '/21852615636/pmaktif.com/mobil_anasayfa_320x50',
            sizes: [
              [320, 50],
              [300, 50],
              [300, 31],
            ],
            id: 'div-gpt-ad-1587047449200-0',
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters('app', ['getCookieSettings']),
  },
  methods: {
    defineAdSlots(key) {
      window.googletag.cmd.push(() => {
        this.adSlots[key].forEach(item => {
          const { image, sizes, id } = item;
          window.googletag.defineSlot(image, sizes, id).addService(window.googletag.pubads());
        });

        window.googletag.pubads().enableSingleRequest();
        window.googletag.pubads().collapseEmptyDivs();
        window.googletag.enableServices();
      });
    },
    pushAds() {
      // window.googletag = window.googletag || { cmd: [] };
      // if (window.googletag && window.googletag.destroySlots) {
      //   window.googletag.destroySlots();
      // }
      // this.defineAdSlots(key);
      // this.adSlots[key].forEach(item => {
      //   window.googletag.cmd.push(() => {
      //     window.googletag.display(item.id);
      //   });
      // });
    },
    pushEvent(eventName, eventData) {
      let eventLog;
      switch (eventName) {
        case 'learn':
          eventLog = eventData;
          break;
        case 'story':
          eventLog = {
            event: 'story',
            story: {
              action: 'Open',
            },
          };
          break;
        case 'chatbot':
          eventLog = {
            event: 'chatbot',
            chatbot: {
              action: 'Open',
            },
          };
          break;
        case 'video':
          eventLog = {
            event: 'video',
            video: {
              name: eventData.name,
              action: eventData.action,
              url: eventData.url,
            },
          };
          break;
        case 'survey':
          eventLog = {
            event: 'survey',
            survey: {
              name: eventData.name,
              action: 'attend',
              date: new Date().toLocaleDateString('tr'),
            },
          };
          break;
        case 'competition':
          eventLog = {
            event: 'competition',
            competition: {
              name: eventData.taskUserId ? 'Yap Kazan' : eventData.name,
              action: eventData.action,
              date: new Date().toLocaleDateString('tr'),
            },
          };
          break;
        case 'filterContent':
          eventLog = {
            event: 'filter_content',
            filter_name: eventData.filterName,
          };
          break;
        // case 'feed':
        //   eventLog = {
        //     event: eventData.scroll ? 'ee - impressions_scroll' : 'ee - impressions',
        //     ecommerce: {
        //       impressions: eventData.feedList.map((x, i) => {
        //         return {
        //           name: x.title,
        //           id: x.feedId,
        //           brand: null,
        //           category: x.category ? x.category.categoryText : null,
        //           list: eventData.listType,
        //           position: i + eventData.lastIndex + 1,
        //         };
        //       }),
        //     },
        //   };
        //   break;
        case 'feedItem':
          eventLog = {
            event: eventData.scroll ? 'ee - impressions_scroll' : 'ee - impressions',
            ecommerce: {
              impressions: {
                name: eventData.feed.title,
                id: eventData.feed.feedId,
                brand: null,
                category: eventData.feed.category ? eventData.feed.category.categoryText : null,
                list: eventData.listType,
                position: eventData.index,
              },
            },
          };
          break;
        case 'contentClicked':
          eventLog = {
            event: 'ee - productClick',
            ecommerce: {
              click: {
                products: [
                  {
                    name: eventData.title,
                    id: eventData.feedId,
                    brand: null,
                    category: eventData.category ? eventData.category.categoryText : null,
                    list: eventData.pageName,
                    position: eventData.index,
                  },
                ],
              },
            },
          };
          break;
        case 'contentView':
          eventLog = {
            event: 'ee - productDetail',
            ecommerce: {
              detail: {
                actionField: { list: eventData.pageName },
                products: [
                  {
                    name: eventData.title,
                    id: eventData.feedId,
                    brand: null,
                    category: eventData.category ? eventData.category.categoryText : null,
                    list: eventData.pageName,
                  },
                ],
              },
            },
          };
          break;
        case 'virtualVisit':
          eventLog = {
            event: 'virtual_visit',
            data: eventData,
          };
          break;
        case 'videoPlayerStart':
          console.log('videoPlayerStart');
          eventLog = {
            event: 'video_player_start',
            data: eventData,
          };
          break;
        case 'slide':
          {
            const { title, order, ...rest } = eventData;
            eventLog = {
              event: 'ee - impressions',
              ecommerce: {
                impressions: {
                  name: title,
                  position: order,
                  ...rest,
                },
              },
            };
          }
          break;
        case 'clickContent':
          {
            if (!eventData) return;
            eventLog = {
              event: 'ee - promoClick',
              category: 'Enhanced Ecommerce',
              action: 'Promotion Clicks',
              label: 'Promotion Clicks',
              nonInteraction: 'false',
              ecommerce: {
                promoView: {
                  promotions: [
                    {
                      id: eventData.bannerId,
                      name: eventData.title,
                      creative: eventData.embedVideo
                        ? eventData.embedVideo
                        : eventData.imageURL
                        ? eventData.imageURL
                        : eventData.image,
                      position: eventData.order,
                    },
                  ],
                },
              },
            };
          }
          break;
        case 'changedContent':
          {
            eventLog = {
              event: 'gaEvent',
              category: 'Enhanced Ecommerce',
              action: 'Promotion Impressions',
              label: 'Promotion Impressions',
              nonInteraction: 'true',
              ecommerce: {
                promoView: {
                  promotions: eventData,
                },
              },
            };
          }
          break;
        case 'pop-up':
          {
            eventLog = {
              event: eventData.event,
              eventCategory: eventData.category,
              eventAction: eventData.action,
              eventLabel: eventData.label,
              eventValue: eventData.value,
              eventNonInteraction: eventData.isNonInteraction,
            };
          }
          break;
        case 'popup_click':
          {
            eventLog = eventData ?? {};
          }
          break;
        case 'hava_durumu':
          {
            eventLog = {
              event: 'hava_durumu',
            };
          }
          break;
        case 'imsakiye_card':
          {
            eventLog = {
              event: 'imsakiye_card',
            };
          }
          break;
        case 'view_promotion':
          {
            eventLog = {
              event: 'view_promotion',
              ecommerce: {
                creative_name: eventData?.creative ?? '',
                promotion_id: eventData?.bannerId ?? '',
                promotion_name: eventData?.title ?? '',
                promotion_link: eventData?.link ?? '',
                content_type: eventData?.type ?? '',
                content_group: eventData.group ?? '',
              },
            };
          }
          break;
        case 'select_promotion':
          {
            eventLog = {
              event: 'select_promotion',
              ecommerce: {
                creative_name: eventData?.creative ?? '',
                promotion_id: eventData?.bannerId ?? '',
                promotion_name: eventData?.title ?? '',
                promotion_link: eventData?.link ?? '',
                content_type: eventData?.type ?? '',
                content_group: eventData.group ?? '',
              },
            };
          }
          break;
        case 'ecommerce': {
          {
            eventLog = {
              event: 'ecommerce',
              ecommerce: null,
            };
          }
          break;
        }
        case 'favorilere_ekle': {
          eventLog = {
            event: 'favorilere_ekle',
            content_type: eventData?.type ?? '',
          };
          break;
        }
        case 'menu_click': {
          if (eventData?.type) {
            eventLog = {
              event: 'menu_click',
              menu_type: eventData.type ?? '',
            };
          } else {
            eventLog = {
              event: 'menu_click',
              menu_section: eventData?.section ?? '',
              menu_content: eventData?.content ?? '',
            };
          }
          break;
        }
        case 'business_partner_click': {
          eventLog = {
            event: 'business_partner_click',
            business_partner: eventData?.partner ?? '',
            button_location: 'menu',
          };
          break;
        }
        case 'yap_kazan': {
          eventLog = {
            event: 'yap_kazan',
            button_location: eventData?.location ?? '',
          };
          break;
        }
        case 'tamamlanmis_yap_kazan': {
          eventLog = {
            event: 'tamamlanmis_yap_kazan',
            button_location: 'do-win',
          };
          break;
        }
        case 'performance_tracking': {
          eventLog = {
            event: 'performance_tracking',
            content_section: eventData?.section ?? '',
          };
          break;
        }
        case 'rozet': {
          eventLog = {
            event: 'rozet',
            content_section: eventData?.type ?? '',
          };
          break;
        }
        case 'indirime_donustur': {
          eventLog = {
            event: 'indirime_donustur',
            button_location: eventData?.location ?? '',
          };
          break;
        }
        case 'kampanyalar': {
          eventLog = {
            event: 'kampanyalar',
            kampanya_ismi: eventData?.name ?? '',
          };
          break;
        }
        case 'on_siparis_click': {
          eventLog = {
            event: 'on_siparis_click',
            button_location: eventData?.location ?? '',
          };
          break;
        }
        case 'tutun_akademi': {
          eventLog = {
            event: 'tutun_akademi',
            button_location: eventData?.location ?? '',
          };
          break;
        }
        case 'sef_dukkaninda': {
          eventLog = {
            event: 'sef_dukkanında',
            button_location: eventData?.location ?? '',
          };
          break;
        }
        case 'sen_sor': {
          eventLog = {
            event: 'sen_sor',
            button_location: eventData?.location ?? '',
          };
          break;
        }
        case 'odül_yagmuru': {
          eventLog = {
            event: 'odül_yagmuru',
            button_location: eventData?.location ?? '',
          };
          break;
        }
        case 'fiyat_listesi_click': {
          eventLog = {
            event: 'fiyat_listesi_click',
            button_location: eventData?.location ?? '',
            partner: eventData?.partner ?? '',
          };
          break;
        }
        case 'hakkinda_click': {
          eventLog = {
            event: 'hakkinda_click',
            button_location: eventData?.location ?? '',
            partner: eventData?.partner ?? '',
          };
          break;
        }
        case 'cuzdanlarim': {
          eventLog = {
            event: 'cuzdanlarim',
            button_location: eventData?.location ?? '',
          };
          break;
        }
        default:
      }
      if (eventLog) {
        window.dataLayer.push(eventLog);
      }
    },
    isCookieEnabled() {
      var cookieEnabled = false;
      if (this.getCookieSettings && this.getCookieSettings.cookieTypes) {
        let performanceCookie = this.getCookieSettings.cookieTypes.find(
          x => x.type === 3 && x.enable,
        );
        cookieEnabled = !!performanceCookie;
      }
      return cookieEnabled;
    },
    pushDataLayerEvent(eventName, eventData) {
      if (this.isCookieEnabled() && window.dataLayer) {
        this.dataLayerPushCounter = 0;
        this.pushEvent(eventName, eventData);
      } else if (this.dataLayerPushCounter < 5) {
        this.dataLayerPushCounter++;
        setTimeout(this.pushDataLayerEvent, 500);
      }
    },
  },
};
