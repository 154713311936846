<template>
  <div id="divYapKazanWrapper" class="container">
    <BrandTab
      idField="id"
      nameField="name"
      :selectedTabId="toolbar.activeTab"
      @select="setActiveTab($event.id)"
      :tabList="tabList"
      :fixed="true"
      :noScroll="true"
    />
    <hr />
    <div class="gtm-ad-slot" id="div-gpt-ad-1587047396774-0"></div>
    <div class="task-list-wrapper">
      <div v-if="showNoTask && showNoTaskData" class="dowin-not-found">
        <img src="@/assets/images/icons/check-dowin.svg" alt="" />
        <p>
          {{ text.description }}
        </p>
        <BrandButton
          class="dowin-not-found-button"
          :size="toolbar.buttonSize"
          @click="
            sendDataLayerEvent(filter.active === 'incomplete') ||
              setActiveTab(filter.active == 'completed' ? 'incomplete' : 'completed')
          "
          >{{ text.button }}</BrandButton
        >
      </div>
      <div v-else>
        <div v-if="!ajaxLoading" class="header">
          <VueText sizeLevel="6" weightLevel="3">{{ text.header }}</VueText>
          <div
            v-if="toolbar.activeTab == 'completed'"
            class="filter-button"
            @click="openFilterModal"
          >
            <VueIcon
              :iconName="icon.filter.name"
              :iconSize="{ width: icon.filter.width, height: icon.filter.height }"
            ></VueIcon>
          </div>
        </div>
        <ul v-if="filter.active == 'completed' && passiveTasks" is="transition-group" name="flip">
          <li v-for="t in passiveTasks" :key="t.taskUserId">
            <div class="task-completed">
              <div class="task-container">
                <div class="title">
                  <div class="task-images">
                    <BrandLogo
                      class="task-image"
                      v-for="partner in t.partners"
                      :key="partner.id"
                      :width="60"
                      :height="60"
                      :image="partner.image"
                    />
                  </div>
                  <div class="task-name">
                    <VueText sizeLevel="3" weightLevel="3" color="grey-30">Yap Kazan Adı</VueText>
                    <VueText sizeLevel="4" weightLevel="3">{{ t.title }}</VueText>
                  </div>
                </div>
                <div class="grid-container" :class="toolbar.activeTab">
                  <div class="grid-item">
                    <VueText color="grey-30" sizeLevel="3" weightLevel="3">Yap Kazan Tipi</VueText>
                  </div>
                  <div class="grid-item">
                    <VueText color="grey-30" sizeLevel="3" weightLevel="3">Ödül</VueText>
                  </div>
                  <div class="grid-item">
                    <VueText color="grey-30" sizeLevel="3" weightLevel="3">Tarih</VueText>
                  </div>
                  <div class="grid-item">
                    <VueText color="grey-40" sizeLevel="3" weightLevel="3">{{
                      t.description
                    }}</VueText>
                  </div>
                  <div class="grid-item">
                    <VueText color="grey-40" sizeLevel="3" weightLevel="3">{{ t.award }}</VueText>
                  </div>
                  <div class="grid-item startEndDate">
                    <VueText color="grey-40" sizeLevel="3" weightLevel="3">{{
                      t.startDate | parseISODate(t.endDate)
                    }}</VueText>
                  </div>
                </div>
              </div>
              <div class="task-status" :class="[t.isSuccess ? 'status-success' : 'status-fail']">
                <VueIcon
                  v-show="t.isSuccess"
                  :width="icon.faceSmile.width"
                  :height="icon.faceSmile.height"
                  :iconName="icon.faceSmile.name"
                  iconColor="#ffffff"
                ></VueIcon>
                <VueIcon
                  v-show="!t.isSuccess"
                  :width="icon.faceSad.width"
                  :height="icon.faceSad.height"
                  :iconName="icon.faceSad.name"
                  iconColor="#ffffff"
                ></VueIcon>
                <VueText color="white-100" sizeLevel="3" weightLevel="4">{{
                  t.isSuccess | statusDisplay
                }}</VueText>
              </div>
            </div>
          </li>
        </ul>
        <ul
          v-else-if="filter.active == 'incomplete' && activeTasks"
          is="transition-group"
          name="rotate"
        >
          <li v-for="t in activeTasks" :key="t.taskUserId">
            <div class="task-incomplete">
              <div class="task-container">
                <div class="title">
                  <div class="task-images">
                    <BrandLogo
                      class="task-image"
                      v-for="partner in t.partners"
                      :key="partner.id"
                      :width="60"
                      :height="60"
                      :image="partner.image"
                    />
                  </div>
                  <div class="task-name">
                    <VueText sizeLevel="3" weightLevel="3">Yap Kazan Adı</VueText>
                    <VueText sizeLevel="7" weightLevel="3">{{ t.title }}</VueText>
                  </div>
                </div>

                <div class="grid-container" :class="toolbar.activeTab">
                  <div class="grid-item">Yap Kazan Tipi</div>
                  <div class="grid-item">Ödül</div>
                  <div class="grid-item date" @click="openInfoModal(t)">
                    Tarih
                    <VueIcon
                      :iconName="icon.info.name"
                      :iconSize="{ width: icon.info.width, height: icon.info.height }"
                    ></VueIcon>
                  </div>
                  <div class="grid-item">
                    <VueText sizeLevel="3" weightLevel="3">{{ t.description }}</VueText>
                  </div>
                  <div class="grid-item">
                    <VueText sizeLevel="3" weightLevel="3">{{ t.award }}</VueText>
                  </div>
                  <div class="grid-item">
                    <VueText sizeLevel="3" weightLevel="3">{{
                      t.startDate | parseISODate(t.endDate)
                    }}</VueText>
                  </div>
                </div>
                <BrandButton
                  class="btn-start"
                  :contentAlignment="constants.flexAlignment.center"
                  @click="startTask(t)"
                  >{{ t.responseTime | activeTaskButtonText(t.taskTypeId) }}</BrandButton
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <BrandSelectModal
      :showModal="filter.showFilter"
      title="Yap Kazan'lar"
      @click="applyFilter"
      @onModalClose="filter.showFilter = false"
    >
      <VueContentHolder class="filter-wrapper" :padding="['0', '20']">
        <div v-for="(g, index) in filter.filters" class="filter-group" :key="'filter_' + index">
          <div class="filter-header" :text="index | filterGroupText" :key="'item_header_' + index">
            {{ index | filterGroupText }}
          </div>
          <VueListItem
            :text="item.Value"
            @click="selectFilterItem(item, index, i)"
            v-for="(item, i) in g"
            :key="index + '_' + i + '_' + renderKey"
            :icon="getFilterIcon(item.Selected)"
            :contentAlignment="constants.flexAlignment.between"
          ></VueListItem>
        </div>
      </VueContentHolder>
    </BrandSelectModal>

    <BrandSelectModal
      :showModal="info.showModal"
      :plainTextModal="true"
      :title="info.title"
      class="modal-wrapper"
      @click="info.showModal = false"
      @onModalClose="info.showModal = false"
    >
      <div class="info-container">
        <VueText sizeLevel="5" weightLevel="3"
          >Yap Kazan'ın bitmesine {{ info.remainingDays }} gün kaldı</VueText
        >
        <div class="progressbar-wrapper">
          <div class="progressbar">
            <div class="completed-part" :style="{ width: `${info.percentage}%` }">
              <VueText
                v-if="info.percentage >= 17"
                class="percentage"
                sizeLevel="6"
                weightLevel="3"
              >
                % {{ info.percentage }}
              </VueText>
            </div>
            <VueText
              v-if="info.percentage < 17"
              :color="'grey-40'"
              class="percentage"
              sizeLevel="6"
              weightLevel="3"
            >
              % {{ info.percentage }}
            </VueText>
          </div>
        </div>
        <VueText sizeLevel="5" weightLevel="2" class="info-desc">{{ info.text }}</VueText>
      </div>
    </BrandSelectModal>
    <div class="popup-container" v-if="isVisibleVirtualVisitPopup">
      <div @click="closePopupShape" class="shape" />
      <img
        @click="openVirtualVisitPopup"
        src="@/assets/images/virtual-visit.png"
        alt="virtual-visit"
        class="popup-image"
      />
    </div>
  </div>
</template>
<script>
import BrandTab from '@/components/brand/BrandTab/BrandTab.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import { COMPONENT_SIZES, ICON_VARIABLES, PARTNER_IMAGES } from '@/constants/component.constants';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import GameApi from '@/services/Api/gamification';
import BrandSelectModal from '@/components/brand/Modals/BrandModal/BrandSelectModal.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import VueListItem from '@/components/shared/VueListItem/VueListItem.vue';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesDoAndWin from '@/router/routes/secure/RoutesDoAndWin';
import StorageHelper from '@/utils/storageHelper';
import StorageProps from '@/mixins/storageProps';
import dateUtils from '@/mixins/dateUtils';
import gtmUtils from '@/mixins/gtmUtils.js';
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo.vue';

export default {
  name: 'DoAndWin',
  mixins: [dateUtils, gtmUtils, StorageProps],
  components: {
    BrandButton,
    VueText,
    VueIcon,
    BrandSelectModal,
    VueContentHolder,
    VueListItem,
    BrandTab,
    BrandLogo,
  },
  data() {
    return {
      tabList: [
        { id: 'incomplete', name: 'AKTİF' },
        { id: 'completed', name: 'TAMAMLANMIŞ' },
      ],
      renderKey: 0,
      showNoTask: false,
      toolbar: { activeTab: 'incomplete', buttonSize: COMPONENT_SIZES.xSmall },
      filter: {
        showFilter: false,
        filters: [],
        active: 'incomplete',
      },
      activeTasks: null,
      passiveTasks: null,
      info: { showModal: false },
      isVisibleVirtualVisitPopup: false,
    };
  },
  filters: {
    activeTaskButtonText(responseTime, taskType) {
      if (taskType === 3) {
        return 'BAŞLA';
      } else {
        if (responseTime) {
          return 'DETAYLAR';
        } else {
          return 'BAŞLA';
        }
      }
    },
    filterGroupText(group) {
      switch (group) {
        case 'brands':
          return 'Partner';
        case 'years':
          return 'Yıl';
        case 'taskTypes':
          return 'Yap Kazan Tipi';
        case 'taskStatus':
          return 'Durum';
        default:
          return '';
      }
    },
    statusDisplay(success) {
      return success ? 'BAŞARILI' : 'BAŞARISIZ';
    },
  },
  beforeMount() {
    this.getTasks();
    //filtre datası servisten alınıp map ediliyor
    GameApi.getDoWinFilters().then(res => {
      if (res && res.data && res.data.Data) {
        this.filter.filters = Object.assign({}, res.data.Data);

        Object.keys(this.filter.filters).forEach(filterGroup => {
          this.filter.filters[filterGroup].forEach((item, key, arr) => {
            //years ile gelen data object array değil string array oyüzden ayrı map ediliyor
            let keyValuePair =
              filterGroup == 'years' ? { Key: item, Value: item.toString() } : { ...item };
            arr[key] = {
              Selected: false,
              ...keyValuePair,
            };
          });
        });
      }
    });
  },
  mounted() {
    this.pushAds('large');
  },
  computed: {
    text() {
      let button = '';
      let description = '';
      let header = '';

      button = `${this.filter.active == 'completed' ? 'AKTİF' : 'TAMAMLANMIŞ'} YAP KAZAN'LARI GÖR`;
      description = `${
        this.filter.active == 'incomplete' ? 'Aktif' : 'Tamamlanmış'
      } Yap Kazan'ın Bulunmamaktadır`;
      header = `${this.toolbar.activeTab == 'completed' ? 'Tamamlanmış' : 'Aktif'} Yap Kazan'larım`;

      return { button, description, header };
    },
    showNoTaskData() {
      let show = false;
      if (
        this.filter.active === 'incomplete' &&
        (!this.activeTasks || this.activeTasks.length === 0)
      ) {
        show = true;
      } else if (
        this.filter.active === 'completed' &&
        (!this.passiveTasks || this.passiveTasks.length === 0)
      ) {
        show = true;
      }
      return show;
    },
    sizes() {
      return COMPONENT_SIZES;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    images() {
      return PARTNER_IMAGES;
    },
    icon() {
      return {
        faceSmile: ICON_VARIABLES.faceSmile,
        faceSad: ICON_VARIABLES.faceSad,
        filter: ICON_VARIABLES.filter,
        checked: ICON_VARIABLES.checked,
        circle: ICON_VARIABLES.circle,
        info: ICON_VARIABLES.info,
      };
    },
  },
  methods: {
    sendDataLayerEvent(completed) {
      if (completed) {
        this.pushDataLayerEvent('ecommerce');
        this.pushDataLayerEvent('tamamlanmis_yap_kazan');
      }
    },
    openInfoModal(task) {
      this.info.title = task.title;
      this.info.percentage = task.taskTimeRatio;
      this.info.remainingDays = task.taskRemainingDay;
      this.info.showModal = true;
      this.info.text = task.infoText;
    },
    openFilterModal() {
      // scrollbarı modalın arkasında kalan ekran yakalamasın diye
      document.documentElement.style.overflow = 'hidden';
      this.filter.showFilter = true;
    },
    applyFilter() {
      let filterString = '';
      Object.keys(this.filter.filters).forEach(x => {
        this.filter.filters[x].forEach(item => {
          if (item.Selected)
            filterString += `${
              x != 'taskStatus' ? x.replace(/\b[a-zA-Z]/g, match => match.toUpperCase()) : 'Status'
            }=${item.Key}&`;
        });
      });

      filterString = filterString ? filterString.slice(0, -1) : '';
      this.getTasks(filterString);
      document.documentElement.style.overflow = 'auto';
      this.filter.showFilter = false;
    },
    getFilterIcon(selected) {
      return selected ? this.icon.checked : this.icon.circle;
    },
    selectFilterItem(item, filterKey, i) {
      this.renderKey += 1;
      this.filter.filters[filterKey][i].Selected = !this.filter.filters[filterKey][i].Selected;
    },
    getTasks(filter) {
      let isActive = this.filter.active == 'incomplete';
      GameApi.getDoWinTasks(isActive, filter).then(res => {
        if (res && res.data && res.data.Data) {
          if (isActive) {
            this.activeTasks = res.data.Data.inCompletedTasks;
            if (!filter && (!this.activeTasks || this.activeTasks.length === 0)) {
              this.showNoTask = true;
            } else {
              this.showNoTask = false;
            }
          } else {
            this.passiveTasks = res.data.Data.completedTasks;
            if (!filter && (!this.passiveTasks || this.passiveTasks.length === 0)) {
              this.showNoTask = true;
            } else {
              this.showNoTask = false;
            }
          }
        }
      });
    },
    setActiveTab(tab) {
      if (tab === 'completed') {
        this.pushDataLayerEvent('ecommerce');
        this.pushDataLayerEvent('tamamlanmis_yap_kazan');
      }
      if (this.toolbar.activeTab != tab) {
        this.toolbar.activeTab = tab;
        this.filter.active = tab;
        this.getTasks();
      }
    },
    startTask(task) {
      if (task.taskTypeId == 10) {
        new StorageHelper({ id: 'PlayableInfo' }).set(task);
        this.$router.push({
          path: `${RoutesRoot.Secure.path}/${RoutesSecure.Playable.path}`,
        });
      } else if (task.isTaskSuperDW && !task.vvStatus) {
        this.isVisibleVirtualVisitPopup = true;
      } else {
        if (!task.referralLink) {
          const warnModalConfig = {
            isModalOpened: true,
            modalText: 'İçerik bulunamadı!',
            modalType: 'alert',
            alertType: 'warn',
            firstButtonText: 'Tamam',
          };
          this.$store.dispatch('app/setModalConfig', warnModalConfig);
        } else {
          GameApi.sendTaskResponse(task.taskUserId).then(() => {
            if ([1, 2, 11].includes(task.taskTypeId)) {
              let x = [1, 11].includes(task.taskTypeId)
                ? 'Yoka Düşmeme Görevini'
                : 'Ürün Bulundurma Görevini';
              let y = [1, 11].includes(task.taskTypeId)
                ? 'ürününde yoka düşmeyerek'
                : 'ürününü bulundurarak';
              let z = task.productName || '';
              let locale = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              };
              let startDate = this.convertISODate(task.startDate).toLocaleDateString('tr', locale);
              let endDate = this.convertISODate(task.endDate).toLocaleDateString('tr', locale);
              let modalText = `<div>Sana özel ${x} tamamlamak için son adım! 3 hafta boyunca "${z}" ${y} "${task.award}" kazan!</div>
      <div style="display: flex; justify-content: center; margin-top: 5px;">
        <div>
          <div>Başlangıç tarihi</div>
          <div style="text-align: left;">Bitiş tarihi</div>
        </div>
        <div>
          <div>:  ${startDate}</div>
          <div>:  ${endDate}</div>
        </div>
      </div>`;
              let detailModalConfig = {
                isModalOpened: true,
                modalType: 'info',
                noIcon: true,
                firstButtonText: 'TAMAM',
                modalText,
              };
              this.$store.dispatch('app/setModalConfig', detailModalConfig);
            } else if (task.taskTypeId == 3) {
              new StorageHelper({
                id: process.env.VUE_APP_STORAGE_TASK_TAKE_QUIZ_KEY,
              }).set({ taskUserId: task.taskUserId, testId: task.testId });
              const route = `${RoutesRoot.Secure.path}/${RoutesSecure.DoAndWin.path}/${RoutesDoAndWin.TakeQuiz.path}`;
              this.$router.push(route);
            } else if (task.taskTypeId === 4) {
              this.$store
                .dispatch('app/setFeedTaskDetail', { ...task, isFromFeed: true })
                .then(() => {
                  new StorageHelper({ id: 'FeedTaskDetail' }).set({ ...task, isFromFeed: true });
                  if (!task.referralLink) return;
                  const targetPart = task.referralLink
                    .split('/')
                    .slice(-2)
                    .join('/');

                  const currentPath = this.$route.path;
                  const newPath = currentPath.replace('/do-and-win', '/' + targetPart);
                  this.$router.push(newPath);
                });
            } else {
              if (task.referralLink) {
                window.location.href = task.referralLink;
              }
            }
          });
        }
      }
    },
    openVirtualVisitPopup() {
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.VirtualVisit.path}`);
      this.isVisibleVirtualVisitPopup = false;
    },
    closePopupShape() {
      this.isVisibleVirtualVisitPopup = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_animations.scss';

.info-container {
  text-align: center;
  padding-top: palette-space-level('20');
  .progressbar-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: palette-space-level('10') palette-space-level('20');

    .progressbar {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      height: palette-space-level('45');
      background-color: #dddddd;
      border-radius: palette-radius-level('12');
      overflow: hidden;
      .completed-part {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(to left, #d20051 0%, #ffa300 100%);
        border-radius: palette-radius-level('12');
        display: flex;
        align-items: center;
        justify-content: center;
        .percentage {
          color: palette-color-level('white', '100');
        }
      }
    }
  }
  .info-desc {
    padding-left: palette-space-level('30');
    padding-right: palette-space-level('30');
  }
}

.grid-container {
  display: grid;
  grid-template-columns: 36% 32% 32%;
  width: 100%;
  border-top: 1px solid palette-color-level('grey', 20);
  &.incomplete {
    .grid-item {
      display: flex;
      justify-content: space-between;
    }
  }
  &.completed {
    .grid-item {
      display: flex;
      align-items: center;
      &.startEndDate {
        // padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .grid-item {
    border-left: 1px solid palette-color-level('grey', 20);
    padding: palette-space-level('10') palette-space-level('10') 0 palette-space-level('10');
    font-size: palette-font-size-level(4);

    &:first-child,
    &:nth-child(3n + 1) {
      border-left: none;
    }
    &:nth-child(n + 4) {
      padding-bottom: palette-space-level(10);
    }
  }
}
.container {
  hr {
    height: 1px;
    width: 100%;
    opacity: 0.5;
    border-top: 1px solid palette-color-level('grey', 20);
  }
  .task-list-wrapper {
    ul {
      list-style-type: none;
    }
    padding: palette-space-level('10');
    margin-bottom: palette-space-level('10');
    display: flex;
    flex-direction: column;

    .header {
      padding-bottom: palette-space-level('25');
      padding-top: palette-space-level('25');
      display: flex;
      justify-content: space-between;
      align-items: center;
      .filter-button {
        border-radius: palette-radius-level('2');
        padding: palette-space-level('10');
        box-shadow: 0 0 palette-space-level('10') 0 rgba(0, 0, 0, 0.15);
      }
    }
    li {
      box-shadow: 0 0 palette-space-level('10') 0 rgba(0, 0, 0, 0.2);
      margin-bottom: palette-space-level('15');
      .task-completed {
        .task-container {
          .title {
            width: 100%;
            height: 100%;
            .task-images {
              display: flex;
              padding: palette-space-level('10');
              min-height: palette-space-level('80');
              .task-image {
                margin-right: palette-space-level('10');
              }
            }
            .task-name {
              border-top: 1px solid rgba(180, 194, 211, 0.5);
              padding: palette-space-level(10);
            }
          }
        }
        .task-status {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: palette-space-level(3);
          p {
            padding-left: palette-space-level(5);
          }
        }
      }
      .task-incomplete {
        .title {
          .task-images {
            display: flex;
            padding: palette-space-level(10);
            min-height: palette-space-level('80');

            .task-image {
              margin-right: palette-space-level(10);
            }
          }
          .task-name {
            border-top: 1px solid palette-color-level('grey', 20);
            padding: palette-space-level(10);

            p:first-child {
              padding-top: palette-space-level(5);
              margin-bottom: palette-space-level(10);
            }
          }
        }
        .btn-start {
          border-radius: 0;
          height: palette-space-level(50);
          &:after {
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
          }
        }
      }
    }
  }
  .status-success {
    @include bg-linear-gradient(
      86deg,
      palette-color-level('green', '20'),
      palette-color-level('green', '30')
    );
  }
  .status-fail {
    @include bg-linear-gradient(
      to left,
      palette-color-level('red', '40'),
      palette-color-level('red', '30')
    );
  }

  .filter-wrapper {
    overflow: auto;
    max-height: 300px;
    .filter-group {
      margin-bottom: palette-space-level(20);
      margin-top: palette-space-level(20);
      .filter-header {
        padding-bottom: palette-space-level(10);
        border-bottom: 1px solid palete-color-level('grey', 20);
        font-size: palette-space-level(20) !important;
        font-weight: bold !important;
      }
    }
  }
}
.dowin-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    padding: 20px;
  }
  p {
    max-width: 240px;
    text-align: center;
    padding: 20px;
  }
  &-button {
    width: unset !important;
  }
}
.shape {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 32;
  background-color: #000;
  opacity: 0.8;
}
.popup-container {
  height: 100%;
}
.shape,
.popup-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 32;
}
.popup-image {
  max-width: 95%;
  max-height: 100%;
}
</style>
