import StorageHelper from '@/utils/storageHelper';
import { mapGetters } from 'vuex';

export default {
  methods: {
    setAppBarTitle(title) {
      let appBarConfig = this.getAppbarConfig;
      appBarConfig.title = title;
      this.$store.dispatch('app/setAppbarConfig', appBarConfig);
    },
  },
  computed: {
    ...mapGetters('app', [
      'getCustomerDetail',
      'getCustomerPoints',
      'getAppbarConfig',
      'getBottomBarStatus',
      'getCookieSettings',
      'getActiveNavItem',
      'getOverlayVisibilityStatus',
      'getMenuItems',
      'getCurrentMenuItems',
    ]),
    ...mapGetters('auth', ['getUserModel', 'getPos']),
    ajaxLoading() {
      return this.getOverlayVisibilityStatus;
    },
    isCookieConsent() {
      return this.getCookieSettings ? this.getCookieSettings.isConsent : 'NotSet';
    },
    menuItems() {
      return this.getMenuItems;
    },
    currentMenuItems() {
      return this.getCurrentMenuItems;
    },
    customerPoints() {
      let point = null;
      //getCustomerPoints, store boş ise local storageden dolduruluyor
      if (!this.getCustomerPoints) {
        point = new StorageHelper({
          id: process.env.VUE_APP_STORAGE_CURRENT_POS_POINTS,
        }).get();
      } else {
        point = this.getCustomerPoints;
      }
      return point;
    },

    customerDetail() {
      let customerDetail = null;
      //customerdetail, store boş ise local storageden dolduruluyor
      if (!this.getCustomerDetail || !this.getCustomerDetail.partners) {
        customerDetail = new StorageHelper({
          id: process.env.VUE_APP_STORAGE_CURRENT_POS_DETAIL,
        }).get();
      } else {
        customerDetail = this.getCustomerDetail;
      }
      return customerDetail;
    },
    currentUser() {
      return this.getUserModel;
    },
    customerCode() {
      return this.getPos?.posCode ?? '';
    },
    isLampUser() {
      let usertype = new StorageHelper({ id: process.env.VUE_APP_STORAGE_USER_ACCOUNT_TYPE }).get();
      return usertype === 'LAMP';
    },
    isPartner() {
      let type = this.customerDetail?.partners?.some(item => item.code === 'PMI');
      return type;
    },
  },
};
